export interface NavigationItem {
  id: string;
  title: string;
  type: 'item' | 'collapse' | 'group';
  translate?: string;
  icon?: string;
  hidden?: boolean;
  url?: string;
  classes?: string;
  exactMatch?: boolean;
  external?: boolean;
  target?: boolean;
  breadcrumbs?: boolean;
  badge?: {
    title?: string;
    type?: string;
  };
  children?: NavigationItem[];
}
export const NavigationItems: NavigationItem[] = [
  {
    id: 'navigation',
    title: 'Navigation',
    type: 'group',
    icon: 'icon-navigation',
    children: [
      {
        id: 'EmpresaMatriz',
        title: 'Cadastros',
        type: 'collapse',
        icon: 'feather icon-home',
        children: [
          {
            id: 'filial',
            title: 'Filiais',
            icon: 'feather icon-user-plus',
            type: 'item',
            url: '/cadastrar/Filial/Empresa'
          },
          {
            id: 'account',
            title: 'Conta Pagamentos',
            icon: 'feather icon-activity',
            type: 'item',
            url: '/cadastro/Conta/Pagamento'
          },
          {
            id: 'Cliente',
            title: 'Clientes',
            icon: 'feather icon-users',
            type: 'item',
            url: '/Cadastrar/Clientes/Empresa'
          }
        ]
      },

      {
        id: 'business',
        title: 'Pagamento',
        type: 'collapse',
        icon: 'feather icon-settings',
        children: [
          {
            id: 'Cliente',
            title: 'Gerar Links',
            icon: 'feather icon-link',
            type: 'item',
            url: '/Pagamento/cliente/Link'
          }
        ]
      }
    ]
  }
];
