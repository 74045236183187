import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { User } from '../_helpers/user';
import { LoginService } from './login.service';
import { LocalStorageService } from './local-storage.service';
import { NotificationService } from './notification.service';
import { InfoPaymentsGenerator } from '../_helpers/InfoPaymentGenerator';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  private currentUserSubject: BehaviorSubject<User | any>;
  private subs: Subscription[] = [];
  currentUser: Observable<User>;

  constructor(
    private router: Router,
    private loginService: LoginService,
    private notificationService: NotificationService,
    private localStorageService: LocalStorageService
  ) {
    this.currentUserSubject = new BehaviorSubject<User | null>(JSON.parse(localStorage.getItem('currentUser')!));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  login(userName: string, password: string) {
    return this.loginService.login(userName, password).pipe(
      map((user) => {
       if (!user.infoPayments) {
          user.infoPayments = InfoPaymentsGenerator.createInfoPayments();
        }
        localStorage.setItem('currentUser', JSON.stringify(user));
        this.currentUserSubject.next(user);
        return user;
      })
    );
  }

  logout() {
    localStorage.removeItem('currentUser');
    this.currentUserSubject.next(null);
    this.router.navigate(['/auth/login']);
  }

  hasAccess() {
    const currentUser = JSON.parse(this.localStorageService.get("currentUser"));

    if (currentUser != null) {
      if (currentUser.token != null) {
        return true;
      }
      else {
        this.notificationService.ngAlertInfo("Atenção!", "Realize o login para acessar essa página.");
        return false;
      }
    }
    else {
      this.notificationService.ngAlertInfo("Atenção!", "Realize o login para acessar essa página.");
      return false;
    }
  }

  hasLogin() {
    this.router.navigate(['/dashboard/default']);
  }

  reset(userName: string){
    return this.loginService.reset(userName)
  }


  register(userData: any): Observable<any> {
    return this.loginService.register(userData);
  }

  changePassword(userData: any): Observable<any>{
    return this.loginService.changePassword(userData)
  }
}
