import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { Reset, User } from '../_helpers/user';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class LoginService {
  private apiUrl = environment.apiUrl;
  private loginApiUrl = `/management-business/v1/user/login`;
  private resetApiUrl = `/management-business/v1/user/password/forgot`;
  private registerApiUrl = `/management-business/v1/user/new`;
  private changePasswordApiUrl = `/management-business/v1/user/password/edit`;
  private editStatusAccountUrl = `/management-business/v1/user/edit/active`
  private sub = new Subscription();

  constructor(private http: HttpClient) {}

  login(userName: string, password: string): Observable<User> {
    return this.http.post<User>(this.loginApiUrl, { userName, password }).pipe(
      map((user) => {
        return user;
      })
    );
  }

  reset(userName: string): Observable<Reset>{
    return this.http.post<Reset>(this.resetApiUrl, {userName}).pipe(
      map(( reset ) => {
        return reset;
      })
    );
  }


  register(userData: any): Observable<any> {
    return this.http.post<any>(this.registerApiUrl, userData).pipe(
      map((response) => {
        return response;
      })
    );
  }

  changePassword(userData: any): Observable<any> {
    return this.http.put<any>(this.changePasswordApiUrl, userData).pipe(
      map((response) => {
        return response;
      })
    );
  }

  activeCustomerAccout(userData: any): Observable<any>{
    return this.http.post<any>(this.editStatusAccountUrl, userData).pipe(
      map(
        (response) => {
          return response;
        }
      )
    )

  }


}
