import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, catchError, filter, finalize, map, Observable, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HttpInterceptorService {
  private lastRequestUrl: string | null = null;
  private requestInFlight$: BehaviorSubject<boolean>;
  constructor() {
      this.requestInFlight$ = new BehaviorSubject(false);
  }

  setHttpStatus(inFlight: boolean) {
      this.requestInFlight$.next(inFlight);
  }

  getHttpStatus(): Observable<boolean> {
      return this.requestInFlight$.asObservable();
  }

  setLastRequestUrl(url: string) {
    this.lastRequestUrl = url;
  }

  getLastRequestUrl(): string | null {
    return this.lastRequestUrl;
  }
}


@Injectable()
export class HTTPListener implements HttpInterceptor {
    private _requests = 0;

    constructor(private status: HttpInterceptorService,
                private router: Router) {}

    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
      this.status.setLastRequestUrl(req.url);
        ++this._requests;
        this.status.setHttpStatus(true);

        return next.handle(req).pipe(
          filter(event => event instanceof HttpResponse),
          map((event: HttpResponse<any>) => {
            if (event.status === 200) {
              const authorization = event.headers.get('Authorization');

              if (authorization) {
                localStorage.setItem('currentUser', JSON.stringify({
                  ...JSON.parse(localStorage.getItem('currentUser') || '{}'),
                  token: authorization
                }));

            }

            return event;
          }}),
            catchError(error => {
                if (error.status === 401) {
                    this.router.navigate(['/AcessoNegado']);
                }

                return throwError(error);
            }),
            finalize(() => {
                --this._requests;
                this.status.setHttpStatus(this._requests > 0);
            })
        );
    }
}
