<ul class="navbar-nav">
  <li>
    <div class="dropdown drp-user" ngbDropdown placement="auto">
      <a href="javascript:" ngbDropdownToggle data-toggle="dropdown">
        <i class="icon feather icon-settings"></i>
      </a>
      <div class="dropdown-menu dropdown-menu-end profile-notification" ngbDropdownMenu>
        <div class="pro-head">

          <span>{{ userName }}</span>
          <a href="javascript:" class="dud-logout" title="Logout" (click)="logout()">
            <i class="feather icon-log-out"></i>
          </a>
        </div>
        <ul class="pro-body">
          <li>
            <a href="javascript:" class="dropdown-item" (click)="goToSettings()"><i class="feather icon-settings" ></i> Configurações</a>
          </li>
        </ul>
      </div>
    </div>
  </li>
</ul>
@if (visibleUserList && this.config['rtl-layout']) {
  <section class="header-user-list" [ngClass]="{ open: visibleUserList }" [@slideInOutRight]>
    <app-chat-user-list
      (ChatToggle)="onChatToggle($event)"
      (ChatCollapse)="this.visibleUserList = !this.visibleUserList; this.chatMessage = false"
    />
  </section>
}
