// angular import
import { Component, DoCheck, OnInit } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';

// bootstrap import
import { NgbDropdownConfig } from '@ng-bootstrap/ng-bootstrap';
import { AuthenticationService } from 'src/app/theme/shared/service/authentication.service';

// project import
import { SharedModule } from 'src/app/theme/shared/shared.module';
import { ThemeConfig } from 'src/app/app-config';

// third party
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-nav-right',
  standalone: true,
  imports: [SharedModule],
  templateUrl: './nav-right.component.html',
  styleUrls: ['./nav-right.component.scss'],
  providers: [NgbDropdownConfig],
  animations: [
    trigger('slideInOutLeft', [
      transition(':enter', [style({ transform: 'translateX(100%)' }), animate('300ms ease-in', style({ transform: 'translateX(0%)' }))]),
      transition(':leave', [animate('300ms ease-in', style({ transform: 'translateX(100%)' }))])
    ]),
    trigger('slideInOutRight', [
      transition(':enter', [style({ transform: 'translateX(-100%)' }), animate('300ms ease-in', style({ transform: 'translateX(0%)' }))]),
      transition(':leave', [animate('300ms ease-in', style({ transform: 'translateX(-100%)' }))])
    ])
  ]
})
export class NavRightComponent implements DoCheck, OnInit{
  // public props
  visibleUserList: boolean;
  chatMessage: boolean;
  friendId: boolean;
  config;
  userName: string = '';

  // constructor
  constructor(
    config: NgbDropdownConfig,
    private authenticationService: AuthenticationService,
    private translate: TranslateService,
    private router: Router
  ) {
    config.placement = 'bottom-right';
    this.visibleUserList = false;
    this.chatMessage = false;
    this.config = ThemeConfig;
    this.authenticationService.currentUser.subscribe((x) => x);
  }

  // public method
  onChatToggle(friend_id) {
    this.friendId = friend_id;
    this.chatMessage = !this.chatMessage;
  }



  ngDoCheck() {
    if (document.querySelector('body').classList.contains('datta-rtl')) {
      this.config['rtl-layout'] = true;
    } else {
      this.config['rtl-layout'] = false;
    }
  }

  ngOnInit() {
    const currentUser = JSON.parse(localStorage.getItem('currentUser')!);
    if (currentUser && currentUser.nameCustomer) {
      this.userName = currentUser.nameCustomer.split(' ')[0];
    }
  }
  useLanguage(language: string) {
    this.translate.use(language);
  }

  logout() {
    this.authenticationService.logout();
  }

  goToSettings() {
    this.router.navigate(['/settings']);
  }
}
