<header>
  <div class="m-header" [style.display]="this.headerStyle">
    <a class="mobile-menu" id="mobile-collapse1" href="javascript:" (click)="this.NavCollapsedMob.emit()"><span></span></a>
    <a [routerLink]="['/dashboard/products']" class="b-brand">
      <span class="b-title">
      <img src="../../../../../assets/images/logo.svg" alt="Logo" class="logo">
    </span>
    </a>
  </div>
  <a class="mobile-menu" [ngClass]="{ on: this.menuClass }" id="mobile-header" href="javascript:" (click)="toggleMobOption()"
    ><i class="feather icon-more-horizontal"></i
  ></a>
  <div class="collapse navbar-collapse px-4" [style.display]="this.collapseStyle">

    <app-nav-right class="ms-auto" />
  </div>
</header>
<div class="pc-menu-overlay" (click)="closeMenu()" (keydown)="handleKeyDown($event)" tabindex="0"></div>
